// src/pages/FieldDetails/styles.ts

import styled from 'styled-components';

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #2c2c2c;

padding: 20px;
`;

// export const AppContainer = styled.div`
//   padding: 20px;
// `;

export const ContentWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto 0 auto;
  color: #ffffff;
  width: 100%;
`;

export const Container = styled.div`
  padding: 20px;
  /* height: 100vh; */
  overflow: hidden;
  background-color:#2c2c2c;
  max-width: 500px;
`;

export const FieldInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  overflow-y: auto;
  height: 200px;
  background-color:#272727;
  border-radius: 10px;
  padding: 5px;
`;

export const FiltersBox = styled.div`
  background-color:transparent;
`;

export const FilterButton = styled.button<{ active: boolean }>`
  background-color: ${({ active }) => (active ? '#555' : 'transparent')};
  color: white;
  border: none;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  
`;

export const VideoList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  /* height: 100%; */
  max-height: 500px;
  align-items: baseline;
  background-color: #272727;
  border-radius: 10px;
  height: 39vh;
`;

export const VideoItem = styled.div`
  width: 45%;
  background-color: transparent;
  border-radius: 10px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;

export const BackButton = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  font-size: 30px;
  cursor: pointer;
  margin-left: 80px;
`;
