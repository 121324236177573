import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Header, VideoContainer, VideoPlayer, DownloadButton, BackButton, FieldInfo, AppContainer } from './styles';
import { Field, Video } from '../../types/types';

const VideoDetails: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { video, field } = location.state as { video: Video, field: Field };

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <AppContainer>
            <Container>
                <Header>
                    <FieldInfo>
                        <img src={field.logoUrl} alt="Logo" style={{ width: 50, height: 50, marginRight: 10 }} />
                        <h2 style={{ color: 'white' }}>{video.fieldName}</h2>
                    </FieldInfo>
                    <BackButton onClick={handleBackClick}>
                        &#8592;
                    </BackButton>
                </Header>
                <VideoContainer>
                    <VideoPlayer controls>
                        <source src={video.downloadUrl} type="video/mp4" />
                        Seu navegador não suporta a reprodução de vídeo.
                    </VideoPlayer>
                    <DownloadButton href={video.downloadUrl} download>
                        Download
                    </DownloadButton>
                </VideoContainer>
            </Container>
        </AppContainer>
    );
};

export default VideoDetails;
