import { Video } from '../types/types';
import axios from 'axios';

export const fetchFootballFields = async (): Promise<any[]> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/fields/all`);

    return response.data;
  } catch (error) {
    console.error('Erro ao buscar os campos de futebol:', error);
    throw error;
  }
};

export const fetchVideosByField = async (fieldId: number): Promise<Video[]> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/videos/all/${fieldId}`);
    return response.data
  } catch (error) {
    console.error('Erro ao buscar vídeos por campo:', error);
    throw error; 
  }
};

