import styled from 'styled-components';

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #2c2c2c;
  padding: 20px;
  box-sizing: border-box;
`;

export const Container = styled.div`
  padding: 20px;
  height: 100vh;
  background-color: #2c2c2c;
  max-width: 500px;
  margin: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const FieldInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const BackButton = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const VideoPlayer = styled.video`
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  margin-bottom: 20px;
`;

export const DownloadButton = styled.a`
  background-color: #555;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #777;
  }
`;
