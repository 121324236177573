// src/pages/Home/styles.ts
import styled from 'styled-components';

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #2c2c2c; /* Cinza escuro */
  padding: 20px;
`;

export const ContentWrapper = styled.div`
  flex: 1; /* Faz com que o ContentWrapper ocupe todo o espaço restante */
  max-width: 500px;
  margin: auto;
  color: #ffffff;
  width: 100%;
`;

export const InputContainer = styled.div`
  margin-bottom: 20px; /* Espaçamento entre o input e a lista */
`;

export const CityInput = styled.input`
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  border-radius: 10px;
  border: 2px solid #555; 
  background-color: transparent; 
  color: #fff; 
  box-sizing: border-box;
`;

export const TeamList = styled.div`
  height: calc(100vh - 100px); 
  overflow-y: auto; 
  padding: 10px 0; 
  border: 1px solid #555;
  border-radius: 10px;
  max-height: 650px;
`;

export const TeamItem = styled.div`
  display: flex;
  align-items: center;
  margin: 7px;
  padding: 10px;
  border: 1px solid #444; 
  border-radius: 10px;
  background-color: #333;
`;

export const Logo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const TeamInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TeamName = styled.h3`
  margin: 0;
  font-size: 1.2rem;
`;

export const TeamCity = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #bbb;
`;

export const Loading = styled.div`
  text-align: center;
  margin: 20px 0;
  font-size: 1rem;
  color: #bbb;
`;
