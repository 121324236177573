import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchVideosByField } from '../../service/apiService';
import { Container, VideoList, VideoItem, FieldInfo, FiltersContainer, FilterButton, FiltersBox, AppContainer, BackButton, ContentWrapper } from './styles';
import { Field, Video } from '../../types/types';

const FieldDetails: React.FC = () => {
    const location = useLocation();
    const { field } = location.state as { field: Field };
    const [videos, setVideos] = useState<Video[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedCourt, setSelectedCourt] = useState<string | null>(null);
    const [selectedDay, setSelectedDay] = useState<string | null>(null);
    const [selectedHour, setSelectedHour] = useState<string | null>(null);
    const [courts, setCourts] = useState<string[]>([]);
    const [days, setDays] = useState<string[]>([]);
    const [hours, setHours] = useState<string[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const videoData = await fetchVideosByField(field.id);
                console.log("----")
                console.log("videoData", videoData)
                console.log("----")
                setVideos(videoData);

                const uniqueCourts = Array.from(new Set(videoData.map(video => video.court)));
                setCourts(uniqueCourts);

                const uniqueDays = Array.from(new Set(videoData.map(video => video.day)));
                setDays(uniqueDays);

                const uniqueHours = Array.from(new Set(videoData.map(video => video.hour)));
                setHours(uniqueHours);
            } catch (error) {
                console.error('Erro ao buscar vídeos:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchVideos();
    }, [field.name]);

    const filteredVideos = videos.filter(video => {
        return (!selectedCourt || video.court === selectedCourt) &&
            (!selectedDay || video.day === selectedDay) &&
            (!selectedHour || video.hour === selectedHour);
    });

    console.log("-----------")
    console.log("filteredVideos", filteredVideos)
    console.log("-----------")

    const handleVideoClick = (video: Video) => {
        navigate(`/video-details/${video.id}`, { state: { video, field } });
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <AppContainer>
            <ContentWrapper>
                <Container>
                    <FieldInfo>
                        <img src={field.logoUrl} alt={`${field.name} logo`} style={{ width: 50, height: 50, marginRight: 10 }} />
                        <h2 style={{ color: 'white' }}>{field.name}</h2>
                        <BackButton onClick={handleBackClick}>
                            &#8592;
                        </BackButton>
                    </FieldInfo>

                    <FiltersContainer>
                        <FiltersBox >
                            {courts.length > 0 && (
                                <>
                                    {courts.map(court => (
                                        <FilterButton
                                            key={court}
                                            onClick={() => setSelectedCourt(court)}
                                            active={selectedCourt === court}
                                        >
                                            {court}
                                        </FilterButton>
                                    ))}
                                </>
                            )}
                        </FiltersBox>

                        <FiltersBox>
                            {days.length > 0 && (
                                <>
                                    {days.map(day => (
                                        <FilterButton
                                            key={day}
                                            onClick={() => setSelectedDay(day)}
                                            active={selectedDay === day}
                                        >
                                            {day}
                                        </FilterButton>
                                    ))}
                                </>
                            )}
                        </FiltersBox>

                        <FiltersBox>
                            {hours.length > 0 && (
                                <>
                                    {hours.map(hour => (
                                        <FilterButton
                                            key={hour}
                                            onClick={() => setSelectedHour(hour)}
                                            active={selectedHour === hour}
                                        >
                                            {hour}
                                        </FilterButton>
                                    ))}
                                </>
                            )}
                        </FiltersBox>
                    </FiltersContainer>

                    <VideoList>
                        {loading ? (
                            <p style={{margin:'auto'}}>Carregando vídeos...</p>
                        ) : (
                            filteredVideos.map((video) => (
                                <VideoItem key={video.id} onClick={() => handleVideoClick(video)}>
                                    <video width="100%" height="100px">
                                        <source src={video.downloadUrl} type="video/mp4" />
                                        Seu navegador não suporta a reprodução de vídeo.
                                    </video>
                                </VideoItem>
                            ))
                        )}
                    </VideoList>
                </Container>
            </ContentWrapper>
        </AppContainer>
    );
};

export default FieldDetails;
